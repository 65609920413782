;(() => {
  'use strict'

  const defaults = {
      header: {
        nav: '.menu-a'
      },
      viewports: {
        wide: 1200,
        desktop: 992,
        tablet: 768,
        mobile: 480
      }
    },
    mucanje = {
      opt: '',

      init: () => {
        mucanje.helperScrollTo()
      },

      helperScrollTo: () => {
        const navItems = document.querySelectorAll(defaults.header.nav)
        for (const navItem of navItems) {
          navItem.addEventListener('click', e => {
            e.preventDefault()
            const targetElement = document.getElementById(
              e.target.dataset.target
            )
            targetElement.scrollIntoView({
              behavior: 'smooth'
            })
          })
        }
      }
    }

  window.mucanje = mucanje
})()

if (document.readyState === 'complete' || document.readyState !== 'loading') {
  mucanje.init()
} else {
  document.addEventListener('DOMContentLoaded', mucanje.init)
}
